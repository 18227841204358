<template>
  <div style="font-size: 16px;text-align: center;vertical-align: center; position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%, -40%)">正在支付中,请稍后……</div>
</template>

<script>
import wx from 'weixin-js-sdk';
import {Toast} from "vant";


export default {
  data() {
    return {
      order_num:''
    }
  },
  mounted() {
    Toast.loading({
      message: "loading…",
      forbidClick: true,
      duration: 3000
    });
   this.minipay()

  },
  methods: {
    minipay: function () {
      const order_num = this.$route.query.order
      this.order_num = order_num
      wx.miniProgram.navigateTo({
        url: '/pages/index/index?foo='+ order_num,
      });

    }
  }
}
</script>

<style scoped>

</style>
